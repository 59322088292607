(function($) {

	$(document).ready(function() {

		// setup mobile nav
		$('#nav-open, #nav-close').on('click', function(event){
			$('html').toggleClass('jsnav');
			event.preventDefault();
		});
		$('#mainnav a.children').on('click', function(event){
			$(this).parent().toggleClass('section');
			event.preventDefault();
		});

		// top link
		$("#top-link").hide();
		$(function () {
			$(window).on('scroll', throttle(function() {
				if ($(this).scrollTop() > 200) {
					$('#top-link').fadeIn();
				} else {
					$('#top-link').fadeOut();
				}
			}, 200));

			// scroll body to 0px on click
			$('#top-link a').click(function () {
				$('body,html').animate({
					scrollTop: 0
				}, 600);
				return false;
			});
		});

		// flex slider
		$('.heroslider').flexslider({
			selector: ".slides > li",
			animation: "slide",
			slideshowSpeed: 4000,
			animationSpeed: 300,
			pauseOnHover: true,
			initDelay: 5000,
			smoothHeight: false,
			start: function(slider) {
				$(slider)
					.find("img.lazyslider:first")
					.each(function () {
						var src = $(this).attr("data-src");
						$(this).attr("src", src).removeAttr("data-src").removeClass("lazyslider");
					});
			},
			before: function (slider) {

				var slides = slider.slides;

				// load current in case something went wrong previously
				var current = slider.animatingTo;
				$(slides[current])
					.find("img.lazyslider")
					.each(function () {
						var src = $(this).attr("data-src");
						$(this).attr("src", src);
						$(this).removeAttr("data-src");
						$(this).removeClass("lazyslider");
					});

				// load next
				var next = current + 1;
				if (next < slider.count) {
					$(slides[next])
						.find("img.lazyslider")
						.each(function () {
							var src = $(this).attr("data-src");
							$(this).attr("src", src);
							$(this).removeAttr("data-src");
							$(this).removeClass("lazyslider");
						});
				}

				// load previous in order to go back
				var previous = current - 1;
				if (previous < 0) {
					previous = slider.count - 1;
					$(slides[previous])
						.find("img.lazyslider")
						.each(function () {
							var src = $(this).attr("data-src");
							$(this).attr("src", src);
							$(this).removeAttr("data-src");
							$(this).removeClass("lazyslider");
						});
				}

			}
		});

		$('.tileslider').flexslider({
			selector: ".slides > li",
			animation: "slide",
			animationLoop: true,
			slideshow: false,
			animationSpeed: 300,
			pauseOnHover: true,
			itemWidth: 227,
		    itemMargin: 16
		});

		// language modal
        var dialogEl = document.getElementById('language-info-dialog');
        var mainEl = document.getElementById('main');
        if (dialogEl !== null && mainEl !== null) {
            var dialog = new window.A11yDialog(dialogEl, mainEl);
            if ($('html').data('page-language') != $('html').data('region-language') && getCookie('_languageDialogShown') !== "true") {
    			document.cookie = "_languageDialogShown=true; path=/";
    			dialog.show();
    			dialogEl.removeAttribute('aria-hidden');
    		}
        }

        // language selector
        $('#language-page-selector .label').click(function(event) {
        	$(this).next().toggle();
        	event.stopPropagation();
        });
        $(window).click(function () {
        	$('#language-page-selector ul').hide();
        });

		// scrolling functionality
		$(window).on('scroll', throttle(function() {
			var fromTop = $(window).scrollTop();
            $("body").toggleClass("scrolled", (fromTop > 100));
		}, 200));

		// correct position of anchor scroll
		$(function () {
			$("a").click(function(){
				if($(this).prop("hash")){
					var hash = $(this).prop("hash");
					var href = $(this).attr("href");
					if (href.split('#')[0] == window.location.pathname) {
						var offset;
						if ($(hash).length) {
							offset = $(hash).offset();
						} else if ($('a[name="' + hash.substr(1) + '"]').length) {
							offset = $('a[name="' + hash.substr(1) + '"]').offset();
						}
						if (offset.top) {
							// get header height
							var height = $('header').outerHeight();
							// calculate position
							var position = offset.top - height; // less header height
							//scroll to element
							$("body,html").animate({
								scrollTop : position
							},200);
							// update url hash
							if(history.pushState) {
							    history.pushState(null, null, href);
							} else {
							    location.hash = hash;
							}
							// prevent default jump
							return false;
						}
					}
				}
			});
		});
		//do the same with urls with hash too (so on page load it will slide nicely)
		if (window.location.hash) {
			var height = $('header').outerHeight();
			var hash = window.location.hash;
			var position = 0;
			if ($(hash).length) {
				position = $(hash).offset().top - height; // less header height
			} else if ($('a[name="' + hash.substr(1) + '"]').length) {
				position = $('a[name="' + hash.substr(1) + '"]').offset().top - height; // less header height
			}
			if (position > 0) {
				$("body,html").animate({
					scrollTop : position
				},200);
				return false;
			}
		}

		// product filter on small screens
		$('.product-filter .filter-link').click(function() {
			// only action on small screens
			if (Modernizr.mq('(max-width: 40em)')) {
				if ($(this).hasClass('current')) {
					$(this).parent().addClass('open');
				} else {
					$(this).parent().removeClass('open');
				}

			}
		});

		// cookie banner
		if (getCookie('_cookies-accepted')) {
			$('body').removeClass('show-cookiebanner');
		}
		$('#accept-cookies').click(function() {
			setCookie('_cookies-accepted', 1, 90);
			$('body').removeClass('show-cookiebanner');
		});

	});

}(jQuery));
