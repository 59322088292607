if (
	(typeof(Storage) !== "undefined" && localStorage.getItem("fontsLoaded") === true)
	||
	(getCookie('fontsLoaded') === 'true' || getCookie('fontsLoaded') === true)
) {
    var htmlEl = document.documentElement;
    if (!htmlEl.classList.contains('fonts-loaded')) {
        htmlEl.classList.add('fonts-loaded');
    }
}
else {

	var fontA = new FontFaceObserver( "Open Sans", { weight: 300 });
	var fontB = new FontFaceObserver( "Open Sans", { weight: 300, style: 'italic' });
	var fontC = new FontFaceObserver( "Open Sans", { weight: 400 });
	var fontD = new FontFaceObserver( "Open Sans", { weight: 600 });

    Promise.all([
    	fontA.load(),
    	fontB.load(),
    	fontC.load(),
    	fontD.load()
    ])
        .then(function() {
            document.documentElement.classList.add('fonts-loaded');
            if (typeof(Storage) !== "undefined") {
            	sessionStorage.setItem("fontsLoaded", true);
            } else {
            	setCookie('fontsLoaded', true);
            }
        })
        .catch(function (err) {
            document.documentElement.classList.remove('fonts-loaded');
            if (typeof(Storage) !== "undefined") {
            	sessionStorage.setItem("fontsLoaded", false);
            } else {
            	setCookie('fontsLoaded', false);
            }
            console.warn('Some fonts did not load:', err);
        });
}
