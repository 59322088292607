(function($) {

	//jQuery.placeholder support
	//if (!$.support.placeholder)
	$.support.placeholder = (function(){
		var i = document.createElement('input');
		return 'placeholder' in i;
	})();

	$(document).ready(function() {
		
		//===============================
		//Check for placeholder support
		//If not present replace with javascript substitute
		//===============================
		var inputText = $('input[type=text],input[type=search]');
		var va; // value
		var ph; // placeholder
		if (!$.support.placeholder) {
			$(inputText).each(function() {
				va = $(this).val();
				ph = $(this).attr('placeholder');
				// if the value is empty, put the placeholder value in it's place
				if (!va || va == '') {
					$(this).val(ph);
				}
			});
			// replaceing the placeholder type effect
			$(inputText).focus(function() {
				va = $(this).val();
				ph = $(this).attr('placeholder');
				if (va == ph) {
					$(this).val('');
				}
			}).blur(function() {
				va = $(this).val();
				ph = $(this).attr('placeholder');
				if (va == '') {
					$(this).val(ph);
				}
			});
		}
		
	});
	
}(jQuery));
